import React, { useEffect, useState } from "react";
import {
  exchangeCodeForToken,
  pinterestTokenValidation,
} from "../../services/AuthService";
import { toast } from "react-toastify";

function Integrations() {
  const [authCode, setAuthCode] = useState("");
  const [tokenValidated, setTokenValidated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  
  useEffect(() => {
    console.log("test");
    const initializeIntegration = async () => {
      setLoading(true);
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");
        if (code) {
          console.log("Authorization code:", code);
          setAuthCode(code);
          window.history.pushState(
            {},
            document.title,
            window.location.pathname
          );
        } else if (!tokenValidated) {
          const response = await pinterestTokenValidation();
          console.log("Token validation response:", response.data.message);
          const isValidated = response.data.message === "Success";
          setTokenValidated(isValidated);
          toast.success(`✅ Token Validated`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      } catch (err) {
        console.error(err);
        toast.error("❌ " + err.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } finally {
        setLoading(false);
      }
    };
    initializeIntegration();
  }, []);

  useEffect(() => {
    const exchangeToken = async () => {
      if (!authCode) return;
      setLoading(true);
      try {
        console.log(authCode);
        await exchangeCodeForToken(authCode);
        setTokenValidated(true);
        toast.success(`✅ Token Validated`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setAuthCode("");
      } catch (err) {
        console.error(err);
        toast.error("❌ "+JSON.parse(err.response.data.message.split("data: ")[1]).message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    exchangeToken();
  }, [authCode]);

  const redirectToPinterest = () => {
    console.log("Redirecting to Pinterest...");
    const baseUrl = `https://www.pinterest.com/oauth/?client_id=${process.env.REACT_APP_PINTEREST_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_PINTEREST_REDIRECT_URL}&response_type=code&scope=boards:read,pins:read,boards:write,pins:write`;
    window.location.href = baseUrl;
  };

  const styles = {
    container: {
      border: "1px solid #ccc",
      borderRadius: "5px",
      padding: "20px",
      margin: "20px",
      width: "max-content",
    },
    header: {
      fontSize: "larger",
      fontWeight: "bold",
      marginBottom: "15px",
    },
    success: {
      color: "green",
      fontSize: "24px",
      display: "flex",
      alignItems: "center",
      gap: "20px",
    },
    button: {
      padding: "10px 20px",
      cursor: "pointer",
      fontSize: "14px",
    },
    error: {
      color: "red",
      marginTop: "10px",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        {tokenValidated
          ? "Pinterest Integration Successful"
          : "Connect Pinterest Here"}
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : tokenValidated ? (
        <div style={styles.success}>
          ✔︎
          <button style={styles.button} onClick={redirectToPinterest}>
            Revalidate
          </button>
        </div>
      ) : (
        <>
          <button style={styles.button} onClick={redirectToPinterest}>
            Validate
          </button>
          {error && <div style={styles.error}>{error}</div>}
        </>
      )}
    </div>
  );
}

export default Integrations;
