
import React, { useEffect, useState } from "react";
import { Badge, Card, Col, Dropdown, Table } from "react-bootstrap";
import { Collapse } from "@mui/material";

import moment from "moment";

function CollapseTableRow({ item, onAction, svg1 }) {
    const [expanded, setExpaned] = useState(false)
    return (
        <>
            <tr onClick={() => setExpaned(exp => !exp)} style={{ cursor: 'pointer' }}>
                <td>{moment(item.createdAt).format("MM/DD/YYYY")}</td>
                <td>{item?.designer?.name}</td>
                <td>{item?.user?.name}</td>
                <td>
                    {item.isReject && !item.isVerify && (
                        <Badge variant="danger light "> Reject</Badge>
                    )}

                    {!item.isReject && !item.isVerify && (
                        <Badge variant="warning light "> Pending</Badge>
                    )}
                    {!item.isReject && item.isVerify && (
                        <Badge variant="success light "> Accept</Badge>
                    )}
                </td>
                <td>
                    <Dropdown>
                        <Dropdown.Toggle
                            variant="secondary light"
                            className="light sharp btn btn-secondary i-false"
                        >
                            {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() => onAction(item._id, "Accept")}
                            >
                                Accept
                            </Dropdown.Item>

                            <Dropdown.Item
                                onClick={() => onAction(item._id, "Reject")}
                            >
                                Reject
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>
            <Collapse in={expanded} timeout="auto" >
                <tr style={{ fontSize: 'larger' }} >
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', marginBottom: '5px', flexWrap: 'wrap', maxWidth: '300px' }}>
                        <div><strong>Project Location:</strong> {item?.projectId?.address}</div>
                        <div><strong>Project Type:</strong>{item?.projectId?.projectType?.join(',')}</div>
                        <div><strong>Project Summary:</strong> {item?.projectId?.projectSummary}</div>
                        <div><strong>Ideal Start Date:</strong> {item?.projectId?.startDate || 'Flexible Dates'}</div>
                        <div><strong>Ideal Completion Date:</strong> {item?.projectId?.endDate || 'Flexible Dates'}</div>
                        <div><strong>Allocated Project Funds:</strong> {item?.projectId?.projectFund ? "$" + item.projectId.projectFund : 'None'}</div>
                        <div><strong>Previous Designer Experience:</strong> {item?.projectId?.workedWithclientDesigner}</div>
                        <div><strong>Project Involvement:</strong></div>
                        <div>{item?.projectId?.involvedYourProject}</div>
                    </div>

                </tr>
            </Collapse>
        </>

    )
}

export default CollapseTableRow
