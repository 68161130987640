/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import dashBoardIcon from "../../../images/dashboard 1.svg";
import usersIcon from "../../../images/users.svg";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() { }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let dashBoard = ["", "dashboard"],
      client = ["client-management"],
      designer = ["interior-designer-management"],
      virtual = ["virtual-consultant"],
      requests = ["request"],
      // requests = ["requests"],
      inquiry = [
        "inquiry-management",
        "inquiry-approval-management",
        "accept-inquiry-list",
      ],
      payments = ["payments", "subscription", "consultant-payments"];

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className=" ai-icon" to="/dashboard">
                <i className="flaticon-381-networking"></i>
                {/* // <img src={dashBoardIcon}></img> */}
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
            <li className={`${client.includes(path) ? "mm-active" : ""}`}>
              <Link to="/client-management" className="ai-icon">
                <i className="flaticon-381-user-1"></i>

                <span className="nav-text">Client Accounts</span>
              </Link>
            </li>
            <li className={`${designer.includes(path) ? "mm-active" : ""}`}>
              <Link to="/interior-designer-management" className="ai-icon">
                <i className="flaticon-381-user-2"></i>

                <span className="nav-text">Member Account</span>
              </Link>
            </li>
            <li className={`${virtual.includes(path) ? "mm-active" : ""}`}>
              <Link to="/virtual-consultant" className="ai-icon">
                <i className="flaticon-381-user-1"></i>

                <span className="nav-text">Virtual Consultant</span>
              </Link>
            </li>

            <li className={`${inquiry.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#">
                <i className="flaticon-381-network"></i>
                <span className="nav-text">Project Inquiry</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${path === "inquiry-approval-management" ? "mm-active" : ""
                      }`}
                    onClick={() => this.props.onClick()}
                    to="/inquiry-approval-management"
                  >
                    Inquiry List
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "accept-inquiry-list" ? "mm-active" : ""
                      }`}
                    onClick={() => this.props.onClick()}
                    to="/accept-inquiry-list"
                  >
                    Approve Inquiry List
                  </Link>
                </li>
                {/* <li>
                  <Link
                    className={`${
                      path === "inquiry-management" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/inquiry-management"
                  >
                    Inquiry Listing
                  </Link>
                </li> */}
              </ul>
            </li>
            <li className={`${requests.includes(path) ? "mm-active" : ""}`}>
              <Link to="/request" className="ai-icon">
                <i className="flaticon-381-user-1"></i>

                <span className="nav-text">Requests</span>
              </Link>
            </li>

            <li
              className={`${payments.includes("payments")
                // path === "request" ? "mm-active" : ""
                }`}
            >
              <Link className="has-arrow ai-icon" to="#">
                <i className="flaticon-381-network"></i>
                <span className="nav-text">Payments</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${path === "subscription" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/subscription"
                  >
                    Subscription
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "consultant-payments" ? "mm-active" : ""
                      }`}
                    onClick={() => this.props.onClick()}
                    to="/consultant-payments"
                  >
                    Consultant payments
                  </Link>
                </li>
              </ul>
            </li>
            <li className={`${client.includes(path) ? "mm-active" : ""}`}>
              <Link to="/integrations" className="ai-icon">
                <i className="flaticon-381-network"></i>

                <span className="nav-text">Integrations</span>
              </Link>
            </li>
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
